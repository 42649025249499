import React, { useState, useEffect } from "react";
import styled from "styled-components";
import servicesIcon from "../images/servicesIcon.png";
import Http from "../helpers/Http";
import Template from "./Template";

const MainServices = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    try {
      Http.get("content/type/2")
        .then((response) => {
          localStorage.setItem(
            "services",
            JSON.stringify(response.data.contents)
          );
          setServices(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Template data={services} image={servicesIcon} type="SERVICE" />
    </Container>
  );
};

export default MainServices;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;
